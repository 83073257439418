;(function($) {

	$(document).ready(function() {

		var transitionEndEvent = 'webkitTransitionEnd oTransitionEnd MSTransitionEnd transitionend';
		var company_test_modal = $('#company-test');
		var company_test_toggle = $('.company-test-toggle');

		company_test_toggle.click(function(e) {
			e.preventDefault();

			var questions = company_test_modal.find('.questions');
			var results = company_test_modal.find('.results');
			var company_test_form = $('#company-test-form');
			var steps = company_test_form.find('.pm-step');
			var steps_length = steps.length;
			var current_step = 0;
			var next_button = company_test_form.find('.navigation button');
			var progress_bar = company_test_form.find('.progress-bar');

			// Reset everything
			$(document).on('lity:remove', function(event, instance) {
				$(document).off('lity:remove');

				current_step = 0;
				company_test_form.promin('reset');
				questions.find('.progress').removeClass('show');
				steps.not('.introduction').removeClass('show');
				steps.not(':not(.introduction)').addClass('show');
				questions.removeClass('d-none');
				results.addClass('d-none').removeClass('show');
			});

			lity(company_test_modal);

			next_button.on('click', function(e) {
				e.preventDefault();

				questions.find('.progress').addClass('show');

				if(current_step < steps_length - 1) {
					company_test_form.promin('show', current_step + 1);
				}
				else {
					company_test_form.promin('submit');
				}

				current_step++;
			});

			// Initialize Promin
			company_test_form.promin({

				actions: {
					submit: 'ajax'
				},
				// Want to update the bar on every change
				events:  {
					change: function(promin_step) {

						progress_bar.css('width', (promin_step / steps_length * 100) + '%');

						if(promin_step > 0) {

							steps.eq(promin_step - 1).removeClass('show');

							setTimeout(function() {
								steps.eq(promin_step).addClass('show');
							});

							var radios = steps.eq(promin_step).find('input[type=radio]');

							radios.click(function() {
								radios.off('click');
								steps.eq(promin_step).find('.navigation').addClass('show');
							});
						}
					}
				},

				// Run this when AJAX is sent
				ajaxCallback: function(data) {

					var json = data.responseJSON;

					if(json.success) {

						results.removeClass('d-none');

						setTimeout(function() {
							results.addClass('show');
							results.on(transitionEndEvent, function() {
								results.off(transitionEndEvent);

								progress_bar.css('width', 0);
								questions.addClass('d-none');
							})
						});

						var chartLabels = new Array();
						var chartContent = new Array();
						var chartSomme = 0;
						var chartResult = 0;

						for(var key = 0; key < json.data.sections.length; ++key) {
							chartLabels.push(json.data.sections[key].title);
							chartContent.push(json.data.sections[key].score.toFixed());
							chartSomme += json.data.sections[key].score;
						}

						chartResult = chartSomme / json.data.sections.length;

						$('.results h3 span').html(chartResult.toFixed() + "%");

						var chartData = {
							labels:   chartLabels,
							datasets: [
								{
									label:                "Test",
									fillColor:            "rgba(100,47,133,0.3)",
									strokeColor:          "rgba(100,47,133,1)",
									pointColor:           "rgba(100,47,133,1)",
									pointStrokeColor:     "#bdda48",
									pointHighlightFill:   "#bdda48",
									pointHighlightStroke: "rgba(100,47,133,1)",
									data:                 chartContent,
								},
							]
						};

						var ctx = $("#test-results").get(0).getContext("2d");
						var radarChart = new Chart(ctx).Radar(chartData, {
							scaleFontSize:       8,
							scaleShowLabels:     true,
							scaleFontStyle:      "normal",
							scaleLabel:          "<%=value%>%",
							scaleBeginAtZero:    true,
							scaleOverride:       true,
							scaleSteps:          4,
							scaleStepWidth:      25,
							scaleStartValue:     0,
							pointLabelFontSize:  16,
							pointLabelFontStyle: "bolder",
							tooltipTemplate:     "<%if (label){%><%=label%>: <%}%><%= value %>%",
							responsive:          true,
							pointDot:            false,
						});
					}
				}
			});
		});
	});

}(jQuery));
